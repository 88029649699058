@media (max-width: 991.98px) {
  .navbar {
    width: auto;
  }
  .content {
    width: auto;
  }
  footer.footer {
    width: auto;
  }
}
